.home-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-text10 {
  display: inline-block;
  text-decoration: none;
}
.home-text11 {
  display: inline-block;
}
.home-text12 {
  display: inline-block;
  text-decoration: none;
}
.home-text13 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-text14 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-text15 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-text16 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-text17 {
  display: inline-block;
}
.home-text18 {
  display: inline-block;
}
.home-text19 {
  display: inline-block;
}
.home-text20 {
  display: inline-block;
}
.home-text21 {
  display: inline-block;
}
.home-text22 {
  display: inline-block;
}
.home-image {
  width: 1128px;
  object-fit: cover;
}
.home-header78 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.home-column {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.home-text23 {
  text-align: center;
}
.home-text26 {
  text-align: center;
}
.home-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
}
.home-text29 {
  transition: 0.3s;
}
.home-text29:hover {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 500ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-button2 {
  text-decoration: none;
}
.home-container3 {
  display: contents;
}
.home-text35 {
  display: inline-block;
}
.home-text36 {
  display: inline-block;
}
.home-text37 {
  display: inline-block;
}
.home-text38 {
  display: inline-block;
}
.home-text39 {
  display: inline-block;
  align-self: center;
  text-align: center;
}
.home-text40 {
  display: inline-block;
}
.home-text41 {
  display: inline-block;
}
.home-text42 {
  display: inline-block;
}
.home-text45 {
  display: inline-block;
}
.home-text46 {
  display: inline-block;
}
.home-text52 {
  display: inline-block;
}
.home-text53 {
  display: inline-block;
}
.home-text54 {
  display: inline-block;
}
.home-text55 {
  display: inline-block;
}
.home-text56 {
  display: inline-block;
}
.home-text59 {
  display: inline-block;
}
.home-text60 {
  display: inline-block;
}
.home-text61 {
  display: inline-block;
}
.home-text62 {
  display: inline-block;
  align-self: center;
  text-align: center;
}
.home-text63 {
  display: inline-block;
}
.home-text64 {
  display: inline-block;
}
.home-text65 {
  display: inline-block;
}
.home-text66 {
  display: inline-block;
  align-self: center;
  text-align: center;
}
.home-text67 {
  display: inline-block;
}
.home-text68 {
  display: inline-block;
}
.home-text69 {
  display: inline-block;
}
.home-text70 {
  display: inline-block;
}
.home-text71 {
  display: inline-block;
}
.home-text72 {
  display: inline-block;
}
.home-text73 {
  display: inline-block;
}
.home-text74 {
  display: inline-block;
}
.home-text77 {
  display: inline-block;
  text-align: left;
}
.home-text78 {
  text-align: left;
}
.home-text80 {
  display: inline-block;
  text-align: left;
}
.home-text81 {
  display: inline-block;
  text-align: left;
}
.home-text82 {
  display: inline-block;
  text-align: left;
}
.home-text83 {
  display: inline-block;
  text-align: center;
}
.home-text84 {
  display: inline-block;
}
.home-text85 {
  display: inline-block;
}
.home-text86 {
  display: inline-block;
}
.home-text87 {
  display: inline-block;
}
.home-text88 {
  display: inline-block;
}
.home-text89 {
  display: inline-block;
}
.home-text90 {
  display: inline-block;
}
.home-text93 {
  display: inline-block;
}
.home-text94 {
  display: inline-block;
}
.home-text97 {
  display: inline-block;
}
.home-text98 {
  display: inline-block;
}
.home-text99 {
  display: inline-block;
}
.home-text100 {
  display: inline-block;
}
.home-text101 {
  display: inline-block;
}
.home-text104 {
  display: inline-block;
}
.home-text105 {
  display: inline-block;
}
.home-text106 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  display: inline-block;
}
@media(max-width: 1600px) {
  .home-image {
    width: 1022px;
    height: 103px;
  }
}
@media(max-width: 1200px) {
  .home-container1 {
    z-index: 100;
    box-shadow: 5px 5px 10px 0px #d4d4d4;
    border-color: var(--dl-color-theme-neutral-dark);
    border-width: 1px;
  }
  .home-image {
    width: 1006px;
    height: 96px;
  }
  .home-text24 {
    font-family: Arial;
  }
}
@media(max-width: 991px) {
  .home-image {
    width: 670px;
    height: 64px;
  }
  .home-text26 {
    color: #e5f3d7;
  }
}
@media(max-width: 767px) {
  .home-text24 {
    font-family: Arial;
  }
  .home-text29 {
    z-index: 100;
  }
}
@media(max-width: 479px) {
  .home-image {
    width: 387px;
    height: 38px;
  }
  .home-actions {
    width: 100%;
    flex-direction: column;
  }
  .home-button1 {
    width: 100%;
  }
  .home-button2 {
    width: 100%;
  }
}
